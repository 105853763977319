module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-VVNZZKEEY6","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"ferragamo-nft.com","enableWebVitalsTracking":true,"exclude":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Ferragamo X Shxpir NFT Experience","short_name":"FerragamoXShxpir","start_url":"/","display":"fullscreen","display_override":"standalone","background_color":"#000000","orientation":"portrait","theme_color":"black","icons":[{"src":"icons/icon-48x48.png?v=af1c1deb26ef9afd8916131ae6a31cfe","sizes":"48x48","type":"image/png"},{"src":"icons/icon-72x72.png?v=af1c1deb26ef9afd8916131ae6a31cfe","sizes":"72x72","type":"image/png"},{"src":"icons/icon-96x96.png?v=af1c1deb26ef9afd8916131ae6a31cfe","sizes":"96x96","type":"image/png"},{"src":"icons/icon-144x144.png?v=af1c1deb26ef9afd8916131ae6a31cfe","sizes":"144x144","type":"image/png"},{"src":"icons/icon-192x192.png?v=af1c1deb26ef9afd8916131ae6a31cfe","sizes":"192x192","type":"image/png"},{"src":"icons/icon-256x256.png?v=af1c1deb26ef9afd8916131ae6a31cfe","sizes":"256x256","type":"image/png"},{"src":"icons/icon-384x384.png?v=af1c1deb26ef9afd8916131ae6a31cfe","sizes":"384x384","type":"image/png"},{"src":"icons/icon-512x512.png?v=af1c1deb26ef9afd8916131ae6a31cfe","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["SangBleu Kingdom","Helvetica"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
